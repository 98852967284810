import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Container, Stack, Typography } from "@mui/material";
import Optionals from "./Optionals";
import ChooseVehicle from "./ChooseVehicle";
import { useLocation } from "react-router-dom";
import ClientForm from "./ClientForm";
import Payment from "./Payment";
import Confirmation from "./Confirmation";
import moment from "moment";
const theme = createTheme({
  palette: {
    primary: {
      main: "#263677",
    },
    secondary: {
      main: "#EE8013",
    },
    danger: "#fa0505",
  },
});

function ReservationCheckout() {
  const { t } = useTranslation();
  const reservationData = useLocation();
  const reservationInfo = reservationData.state;
  const [avaiabilities, setVehicleAvaiabilities] = useState(
    reservationInfo?.avaiabilities
  );
  const [reservationDetails, setReservationDetails] = useState(
    reservationInfo?.reservationDetails
  );
  const [wasCarChoosed, setWasCarChoosed] = useState(false);
  const [wasOptionalsChoosed, setWasOptionalsChoosed] = useState(false);
  const [wasClientDetailsFiled, setWasClientDetailsFiled] = useState(false);
  const [vehicleChoosedDetails, setVehicleChoosedDetails] = useState(false);
  const [vehicleExtras, setVehicleExtras] = useState(null);
  const [totalRentalValue, setTotalRentalValue] = useState(0);
  const [clientDetails, setClientDetails] = useState(null);
  const [reservationFinalData, setReservationFinalData] = useState(null);
  const [paymentAproved, setPaymentAproved] = useState(false);
  const [paypalOrderId, setPaypalOrderId] = useState("");
  const [checkInData, setCheckInData] = useState("");
  const [reservationConfirmed, setReservationConfirmed] = useState(false);
  const [totalDays, setTotalDays] = useState(0);
  const [pageChangedFromOptional, setPageChangedFromOptional] = useState(false);
  const [youngDriverClient, setYoungDriverClient] = useState(false);
  const [pickUpDesk, setPickUpDesk] = useState(reservationInfo?.pickUpDesk);
  const [dropOffDesk, setDropOffDesk] = useState(reservationInfo?.dropOffDesk);
  const [paymentType, setPaymentType] = useState("full");

  useEffect(() => {
    if (paymentAproved) {
      const itemName = "itemName";
      const itemPrice = "itemPrice";
      const itemNameKey = `${itemName}[${vehicleChoosedDetails.vehicleCode}]`;
      const itemPriceKey = `${itemPrice}[${vehicleChoosedDetails.vehicleCode}]`;

      const equipment = {};

      vehicleExtras?.extraEquipments?.forEach((item) => {
        const { Equipment, Charge } = item;
        const { Description, EquipType } = Equipment;
        const { Amount, IncludedInRate } = Charge;
        const itemName = `itemName[${EquipType}]`;
        const itemPrice = `itemPrice[${EquipType}]`;
        const pricedEquips = `pricedEquips[${EquipType}]`;

        equipment[itemName] = Description;
        equipment[itemPrice] = Amount;
        equipment[pricedEquips] = IncludedInRate;
      });

      const coverages = {};
      vehicleExtras?.extraCoverages?.forEach((item) => {
        const { Coverage, Charge } = item;
        const { CoverageType } = Coverage;
        const { IncludedInRate } = Charge;
        const coverage = `coverages[${CoverageType}]`;
        coverages[coverage] = IncludedInRate;
      });

      const data = {
        ...reservationDetails,
        loc_pickup: pickUpDesk,
        loc_return: dropOffDesk,
        SIPP: vehicleChoosedDetails.vehicleCode,
        [itemNameKey]: vehicleChoosedDetails.vehicleModel,
        [itemPriceKey]: vehicleChoosedDetails.totalPrice,
        ...clientDetails,
      };

      setReservationFinalData(data);

      submitData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasClientDetailsFiled, paymentAproved]);

  const submitData = async (data) => {
    try {
      const response = await fetch(
        "https://www.whynotcarrental.com/Backend/reservationSub.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        // Wait for the response to be parsed as JSON

        const reservationSubmited = await response.json();

        setReservationConfirmed(true);

        // Handle the response here
        const checkData = {
          idreserva:
            reservationSubmited.VehResRSCore.VehReservation.VehSegmentCore
              .ConfID.ID,
          customer_name:
            reservationSubmited.VehResRSCore.VehReservation.Customer.Primary
              .PersonName.GivenName,
          approvalCode: paypalOrderId,
          amount: totalRentalValue.toFixed(2),
          rph: "PayPal",
        };
        setCheckInData(checkData);

        //check in request
        try {
          const response = await fetch(
            "https://www.whynotcarrental.com/Backend/reservationCheckIn.php",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: JSON.stringify(checkData),
            }
          );

          if (response.ok) {
            // Wait for the response to be parsed as JSON

            const reservationCheckInSubmited = await response.json();
          }
        } catch (error) {}
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (youngDriverClient) {
      setTotalRentalValue(totalRentalValue + 40);
    }
  }, [youngDriverClient]);

  useEffect(() => {
    function calculateTotalDays(
      pickUpDate,
      pickUpTime,
      returnDate,
      returnTime
    ) {
      const pickUpDateTime = moment(
        `${pickUpDate} ${pickUpTime}`,
        "YYYY-MM-DD HH:mm"
      );
      const returnDateTime = moment(
        `${returnDate} ${returnTime}`,
        "YYYY-MM-DD HH:mm"
      );

      // Calculate the difference in total minutes
      const diffInMinutes = returnDateTime.diff(pickUpDateTime, "minutes");

      // Calculate total days by dividing by minutes in a day, rounding up
      const updatedDays = Math.ceil(diffInMinutes / 1440);

      setTotalDays(updatedDays);
    }
    calculateTotalDays(
      reservationDetails?.date_pickup,
      reservationDetails?.time_pickup,
      reservationDetails?.date_return,
      reservationDetails?.time_return
    );
  }, [
    reservationDetails?.date_pickup,
    reservationDetails?.date_return,
    reservationDetails?.time_pickup,
    reservationDetails?.time_return,
    wasCarChoosed,
  ]);

  const myVehiclePageHandler = () => {
    if (wasCarChoosed) {
      setPageChangedFromOptional(true);
      setWasCarChoosed(false);
    }
    setWasCarChoosed(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack
        spacing={2}
        alignItems={"center"}
        direction="column"
        sx={{
          backgroundColor: "primary.main",
          textAlign: "center",
        }}
      >
        <Breadcrumbs
          maxItems={reservationInfo?.width > 500 ? 8 : 2}
          separator={<NavigateNextIcon fontSize="large" />}
          aria-label="breadcrumb"
        >
          {[
            {
              id: 0,
              label: t("rentalPeriod"),
              to: "/",
              onClick: null,
              state: avaiabilities,
              condition: true,
            },
            {
              id: 1,
              label: t("myVehicle"),
              onClick: wasOptionalsChoosed ? null : myVehiclePageHandler,
              state: {
                reservationDetails,
                avaiabilities,
                reservationInfo,
                pickUpDesk,
                dropOffDesk,
              },
              condition: true,
            },
            {
              id: 2,
              label: t("optionals"),
              onClick: () => setWasOptionalsChoosed(false),
              state: reservationInfo,
              condition: wasCarChoosed,
            },
            {
              id: 3,
              label: t("clientData"),
              onClick: () => setWasClientDetailsFiled(false),
              state: reservationInfo,
              condition: wasOptionalsChoosed,
            },
            {
              id: 4,
              label: t("payment"),
              onClick: null,
              state: reservationInfo,
              condition: wasClientDetailsFiled,
            },
            {
              id: 5,
              label: t("confirmation"),
              onClick: null,
              state: reservationInfo,
              condition: reservationConfirmed,
            },
          ]
            .filter((item) => item.condition) // Only render breadcrumbs meeting conditions
            .map((item, index, breadcrumbs) => {
              // Allow navigation only to the immediately previous breadcrumb
              const isClickable =
                index === breadcrumbs.length - 1 ||
                index === breadcrumbs.length - 2;

              return (
                <Typography
                  key={item.id}
                  variant="h6"
                  component="div"
                  sx={{
                    color: "white",
                    textAlign: "center",
                    opacity: isClickable ? 1 : 0.5, // Dim breadcrumbs that aren't clickable
                    pointerEvents: isClickable ? "auto" : "none", // Disable pointer events for non-clickable links
                  }}
                >
                  {isClickable ? (
                    <Link
                      to={item.to}
                      underline="hover"
                      color="inherit"
                      aria-current="page"
                      state={item.state}
                      onClick={item.onClick}
                    >
                      {item.label}
                    </Link>
                  ) : (
                    <span>{item.label}</span> // Show non-clickable breadcrumbs as plain text
                  )}
                </Typography>
              );
            })}
        </Breadcrumbs>
      </Stack>
      <Container>
        {reservationConfirmed ? (
          <Confirmation
            checkInData={checkInData}
            reservationDetails={reservationDetails}
            vehicleChoosedDetails={vehicleChoosedDetails}
            vehicleExtras={vehicleExtras}
            clientDetails={clientDetails}
            totalRentalValue={totalRentalValue}
            pickUpDesk={pickUpDesk}
            dropOffDesk={dropOffDesk}
            totalDays={totalDays}
            paymentType={paymentType}
          />
        ) : wasClientDetailsFiled ? (
          <Payment
            vehicleChoosedDetails={vehicleChoosedDetails}
            reservationInfo={reservationDetails}
            clientDetails={clientDetails}
            setPaymentAproved={setPaymentAproved}
            vehicleExtras={vehicleExtras}
            totalRentalValue={totalRentalValue}
            paymentAproved={paymentAproved}
            pickUpDesk={pickUpDesk}
            dropOffDesk={dropOffDesk}
            totalDays={totalDays}
            youngDriverClient={youngDriverClient}
            setPaymentType={setPaymentType}
            paymentType={paymentType}
            setPaypalOrderId={setPaypalOrderId}
            setTotalRentalValue={setTotalRentalValue}
          />
        ) : wasOptionalsChoosed ? (
          <ClientForm
            reservationInfo={reservationDetails}
            setWasClientDetailsFiled={setWasClientDetailsFiled}
            clientDetails={clientDetails}
            setClientDetails={setClientDetails}
            pickUpDesk={pickUpDesk}
            dropOffDesk={dropOffDesk}
            setYoungDriverClient={setYoungDriverClient}
            setVehicleExtras={setVehicleExtras}
            vehicleExtras={vehicleExtras}
            youngDriverClient={youngDriverClient}
          />
        ) : wasCarChoosed ? (
          <Optionals
            vehicleChoosedDetails={vehicleChoosedDetails}
            reservationInfo={reservationDetails}
            setVehicleExtras={setVehicleExtras}
            setWasOptionalsChoosed={setWasOptionalsChoosed}
            setTotalRentalValue={setTotalRentalValue}
            pickUpDesk={pickUpDesk}
            dropOffDesk={dropOffDesk}
            totalDays={totalDays}
            wasCarChoosed={wasCarChoosed}
          />
        ) : (
          <ChooseVehicle
            setVehicleAvaiabilities={setVehicleAvaiabilities}
            setReservationDetails={setReservationDetails}
            reservationInfo={reservationDetails}
            avaiabilities={avaiabilities}
            setWasCarChoosed={setWasCarChoosed}
            setVehicleChoosedDetails={setVehicleChoosedDetails}
            pickUpDesk={pickUpDesk}
            dropOffDesk={dropOffDesk}
            setPickUpDesk={setPickUpDesk}
            setDropOffDesk={setDropOffDesk}
            totalDays={totalDays}
            choosedCar={reservationInfo?.choosedCar}
            wasCarChoosed={wasCarChoosed}
            pageChangedFromOptional={pageChangedFromOptional}
            setTotalDays={setTotalDays}
          />
        )}
      </Container>
    </ThemeProvider>
  );
}
export default ReservationCheckout;
