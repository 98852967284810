import React, { useMemo } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  BIG,
  CABRIO,
  ELETRIC,
  MEDIUM,
  MINIVAN,
  PREMIUM,
  SCOOTERS,
  SMALL,
  SUVS,
  VAN,
  VEHICLES,
} from "../../helpers/Constants";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { HashLink as Link } from "react-router-hash-link";
import { vehicleCodeChanger } from "../../helpers/vehicleCodeChanger";
import opelAstra from "../../Assets/img_car/detail/18.opel_astra.png";
import peugeot308 from "../../Assets/img_car/detail/peugeot308.png";
import peugeot3008 from "../../Assets/img_car/detail/peugeot3008.png";
import daciaJogger from "../../Assets/img_car/detail/36.Dacia_Jogger.png";
import peugeot108 from "../../Assets/img_car/detail/3.Peugeot_108.png";
import opelInsigniaStation from "../../Assets/img_car/detail/opel_insignia_station.png";
import miniCooperOne from "../../Assets/img_car/detail/miniOneAutomatic.webp";
// import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
// import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
// import SettingsIcon from "@mui/icons-material/Settings";
// import DoorSlidingIcon from "@mui/icons-material/DoorSliding";
// import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
// import InfoSharpIcon from "@mui/icons-material/InfoSharp";

const responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 4 },
  desktop: { breakpoint: { max: 3000, min: 1300 }, items: 4 }, // Display 4 cars
  smallDesktop: { breakpoint: { max: 1300, min: 1024 }, items: 3 }, // Display 4 cars
  tablet: { breakpoint: { max: 1024, min: 768 }, items: 2 },
  mobile: { breakpoint: { max: 768, min: 0 }, items: 1 },
};

const CarouselComponent = React.memo(({ setQuoteMessage, setChoosedCar }) => {
  const { t } = useTranslation();

  function vehicleType(code) {
    if (SCOOTERS.includes(code)) return t("scooter");
    if (BIG.includes(code)) return t("large");
    if (SUVS.includes(code)) return t("suv");
    if (SMALL.includes(code))
      return code === "NCMV"
        ? `${t("small")} ${t("withCanvasEletricRoof")}`
        : code === "NBMV"
        ? `${t("small")} ${t("withPanoramicRoof")}`
        : t("small");
    if (MEDIUM.includes(code)) return t("medium");
    if (CABRIO.includes(code)) return t("cabrio");
    if (ELETRIC.includes(code)) return "eletric";
    if (PREMIUM.includes(code)) return t("premium");
    if (MINIVAN.includes(code)) return t("miniVan");
    if (VAN.includes(code)) return t("van");
    return "Unknown category";
  }

  function checkFuelType(fuel, code) {
    if (code === "NTMV" || code === "CFMV" || code === "CDMV") {
      return t("petrol");
    }

    return fuel === "Petrol" ? t("petrol") : t("diesel");
  }

  return (
    <Box sx={{ width: "100%", margin: "0 auto" }}>
      <Carousel swipeable draggable infinite responsive={responsive}>
        {VEHICLES?.map(
          (
            {
              id,
              brand,
              fuelType,
              transmission,
              picture,
              year,
              doors,
              seats,
              code,
            },
            index
          ) => (
            <Card
              key={index}
              sx={{
                borderRadius: 3,
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                padding: 2,
                margin: 2,
                height: 650,
              }}
            >
              {/* Image with Badge */}
              <Box
                sx={{
                  position: "relative",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  align="center"
                  color="primary.main"
                  component="div"
                >
                  {t("group")} {vehicleCodeChanger(code)}
                </Typography>
                <LazyLoadImage
                  alt="CAR"
                  src={
                    picture
                      ? process.env.PUBLIC_URL + "img_car/detail/" + picture
                      : code === "CDAV"
                      ? opelAstra
                      : code === "CDAD"
                      ? peugeot308
                      : code === "CGAD"
                      ? peugeot3008
                      : code === "JVMR"
                      ? daciaJogger
                      : code === "MDMV"
                      ? peugeot108
                      : code === "CWMD"
                      ? opelInsigniaStation
                      : code === "CTAV"
                      ? miniCooperOne
                      : null
                  }
                  style={{
                    height: 220, // Fixed height for consistency
                    width: 300, // Fixed width
                    maxHeight: "100%", // Ensure the image doesn’t exceed the container's height
                    maxWidth: "100%", // Ensure the image doesn’t exceed the container's width
                    objectFit: "contain", // Fit the image within the container, may result in some empty space
                    display: "block", // Center the image horizontally
                    margin: "0 auto", // Center the image horizontally
                  }}
                />
              </Box>

              {/* Car Info */}
              <CardContent>
                {/* Brand Name */}
                <Typography variant="h6" fontWeight="bold">
                  {brand}
                </Typography>

                {/* "Or Similar" Text */}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mb: 1 }}
                >
                  {t("orSimilar")}{" "}
                  <Typography
                    variant="body2"
                    component={"span"}
                    sx={{ fontSize: "0.7rem", color: "gray" }}
                  >
                    ({vehicleType(code)})
                  </Typography>
                </Typography>

                {/* Car Details in Separate Rows */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column", // Display each detail on a new row
                    gap: 1, // Adds spacing between rows
                    paddingBottom: 2,
                  }}
                >
                  <Typography variant="body2">
                    <Tooltip title={t("fuelType")}>
                      <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/material/24/gas-station--v1.png"
                        alt="gas-station--v1"
                      />
                    </Tooltip>{" "}
                    {checkFuelType(fuelType, code)}
                  </Typography>
                  <Typography variant="body2">
                    <Tooltip title={t("gearbox")}>
                      <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/material/24/gearbox-selector.png"
                        alt="gearbox-selector"
                      />
                    </Tooltip>{" "}
                    {[
                      "EDAV",
                      "MDAV",
                      "CTAV",
                      "RVAD",
                      "HDAE",
                      "CDAV",
                      "CDAD",
                      "CGAV",
                      "CGAD",
                      "DDAD",
                      "PDAD",
                      "SFAD",
                    ].includes(code)
                      ? t("automatic")
                      : t("manual")}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    <Tooltip title={t("doors")}>
                      <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/material/24/car-door--v2.png"
                        alt="car-door--v2"
                      />
                    </Tooltip>{" "}
                    {doors}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    <Tooltip title={t("seats")}>
                      <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/material/24/aircraft-seat-middle.png"
                        alt="aircraft-seat-middle"
                      />{" "}
                    </Tooltip>
                    {seats}
                  </Typography>

                  {/* Show only if code === "PDAD" */}
                  {code === "PDAD" && (
                    <Typography variant="body2">
                      <Tooltip title={t("mandatoryExcess")}>
                        <img
                          width="24"
                          height="24"
                          src="https://img.icons8.com/material/24/info--v1.png"
                          alt="info--v1"
                        />{" "}
                      </Tooltip>
                      {t("mandatoryExcess")}
                    </Typography>
                  )}
                </Box>
              </CardContent>

              {/* Actions */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingX: 2,
                  paddingBottom: 2,
                }}
              >
                <Button variant="contained" size="small">
                  <Link
                    aria-hidden="true"
                    smooth
                    to="#body"
                    onClick={() =>
                      setChoosedCar({
                        code: code,
                        brand: brand,
                        picture: picture,
                      })
                    }
                  >
                    {t("reserve")}
                  </Link>
                </Button>
                <Button variant="outlined" size="small">
                  <Link
                    aria-hidden="true"
                    smooth
                    to="#contacts"
                    onClick={() =>
                      setQuoteMessage(
                        t("quoteMessage") + vehicleCodeChanger(code)
                      )
                    }
                  >
                    {t("requestQuote")}
                  </Link>
                </Button>
              </Box>
            </Card>
          )
        )}
      </Carousel>
    </Box>
  );
});

export default CarouselComponent;
