import React, { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Container, createTheme, ThemeProvider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#38AECC",
    },
    terceary: {
      main: "#F4F4F4",
    },
  },
});

const faqs = [
  {
    category: "Reservas e Aluguer",
    questions: [
      {
        question: "Como posso fazer uma reserva?",
        answer:
          "As reservas podem ser feitas através do nosso site, por telefone, por email ou diretamente no nosso balcão.",
      },
      {
        question: "Posso modificar ou cancelar a minha reserva?",
        answer:
          "Sim, pode modificar ou cancelar a sua reserva gratuitamente até 48 horas antes da data de levantamento.",
      },
      {
        question: "Há um período mínimo/máximo de aluguer?",
        answer:
          "O período mínimo de aluguer é de 24 horas. Não há limite máximo para alugueres.",
      },
      {
        question: "Posso alugar um carro sem cartão de crédito?",
        answer:
          "É necessário um cartão de crédito válido para o depósito de segurança. Em casos especiais, aceitamos cartões de débito mediante análise.",
      },
    ],
  },
  {
    category: "Condições do Aluguer",
    questions: [
      {
        question: "Quais são os requisitos para alugar um carro?",
        answer:
          "Idade mínima de 21 anos, carta de condução válida há pelo menos 2 anos e documento de identificação válido (passaporte ou BI). ",
      },
      {
        question: "Taxa para Condutores Jovens",
        answer:
          "A taxa para condutores jovens tem o valor de 40 euros e é aplicada para as idades compreendidas entre 21 e 25 anos.",
      },
      {
        question: "Existe algum limite de quilometragem?",
        answer:
          "Oferecemos quilometragem ilimitada em todos os nossos veículos.",
      },
      {
        question: "Quais são as políticas de combustível?",
        answer:
          "Trabalhamos com política 'Cheio-Cheio'. O carro deve ser devolvido com o mesmo nível de combustível com que foi entregue.",
      },
    ],
  },
  {
    category: "Seguros e Proteção",
    questions: [
      {
        question: "Que tipo de seguros estão incluídos?",
        answer:
          "O aluguer inclui seguro de responsabilidade civil, danos próprios com franquia e proteção contra roubo. Oferecemos coberturas adicionais para eliminar a franquia.",
      },
      {
        question: "O que devo fazer em caso de acidente ou avaria?",
        answer:
          "Entre em contacto connosco imediatamente. Forneceremos assistência 24 horas e orientações sobre os próximos passos.",
      },
      {
        question: "Posso adicionar condutores extra?",
        answer:
          "Sim, é possível adicionar condutores adicionais ao contrato por um custo extra. Todos os condutores devem cumprir os requisitos de idade e carta de condução.",
      },
    ],
  },
  {
    category: "Levantamento e Devolução",
    questions: [
      {
        question: "Onde posso levantar e devolver o carro?",
        answer:
          "Temos pontos de levantamento e devolução no Aeroporto da Madeira, Funchal e outros locais mediante pedido. Também é possivel proceder ao levantamento e devolução no seu alojamento por um custo extra.",
      },
      {
        question: "Posso devolver o carro fora do horário de funcionamento?",
        answer:
          "Sim, oferecemos a possibilidade de devolução fora de horas através de um custo extra. Entre em contacto connosco para combinar os detalhes.",
      },
    ],
  },
  {
    category: "Extras e Serviços",
    questions: [
      {
        question: "Que extras estão disponíveis?",
        answer:
          "Cadeiras de bebé, assentos elevatórios , entre outros. Estes podem ser adicionados à sua reserva por um custo adicional.",
      },
      {
        question:
          "Posso alugar um carro para atravessar para outras ilhas ou o continente?",
        answer:
          "Não permitimos o transporte dos veículos para fora da arquipélago da Madeira. Apenas é possível transportar o veículo no ferry entre as ilhas da Madeira e Porto Santo, por um custo extra.",
      },
    ],
  },
  {
    category: "Pagamentos e Caução",
    questions: [
      {
        question: "Quais os métodos de pagamento aceites?",
        answer:
          "Aceitamos cartões de crédito, débito e dinheiro. O depósito de segurança deve ser feito com cartão de crédito.",
      },
      {
        question: "Quanto é o valor da caução?",
        answer:
          "O valor varia conforme o modelo do carro e o seguro escolhido, mas geralmente varia entre 1000€ e 3000€.",
      },
    ],
  },
];

const FAQ = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Container>
          <Typography
            variant="h3"
            component="div"
            sx={{
              flexGrow: 1,
              color: "primary.main",
              textAlign: "center",
              mt: "1rem",
            }}
          >
            {t("faq")}
          </Typography>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              color: "primary.main",
              textAlign: "center",
              mt: "1rem",
            }}
          >
            Tem dúvidas sobre o aluguer de viaturas na Madeira? Consulte as
            perguntas mais frequentes e encontre todas as informações
            necessárias para uma experiência de aluguer tranquila e segura.
          </Typography>
          <div>
            {faqs.map((section, index) => (
              <div key={index}>
                <Typography
                  variant="h5"
                  sx={{ color: "primary.main", mt: 4, mb: 2 }}
                >
                  {section.category}
                </Typography>
                {section.questions.map((faq, idx) => (
                  <Accordion key={idx}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{faq.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            ))}
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "left",
                mt: "1rem",
              }}
            >
              Precisa de mais informações?
            </Typography>
            <Button variant="outlined" sx={{ mt: 2 }}>
              <Link smooth to="/#contacts">
                {t("contactUs")}
              </Link>
            </Button>
          </div>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default FAQ;
