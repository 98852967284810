export const LANGUAGES = [
  { label: "Portuguese", code: "pt" },
  { label: "English", code: "en" },
];

export const EXTRAS = [
  {
    id: 0,
    extraName: "babySeat",
    max: 4,
    min: 0,
    value: 8,
    quantity: 0,
    maxValue: 64,
    totalValue: 0,
    totalByDay: true,
  },
  {
    id: 1,
    extraName: "babySeatIsofix",
    max: 4,
    min: 0,
    value: 12,
    quantity: 0,
    maxValue: 96,
    totalValue: 0,
    totalByDay: true,
  },
  {
    id: 2,
    extraName: "boosterSeat",
    max: 4,
    min: 0,
    value: 4,
    quantity: 0,
    maxValue: 32,
    totalValue: 0,
    totalByDay: true,
  },
  {
    id: 4,
    extraName: "additionalDriver",
    max: 8,
    min: 0,
    value: 30,
    quantity: 0,
    maxValue: 240,
    totalValue: 0,
    totalByDay: false,
  },
  {
    id: 5,
    extraName: "fullInsurance",
    max: 1,
    min: 0,
    value: 0,
    quantity: 0,
    maxValue: 10000,
    totalValue: 0,
    totalByDay: true,
  },
  {
    id: 3,
    extraName: "youngDriver",
    max: 1,
    min: 0,
    value: 40,
    quantity: 0,
    maxValue: 200,
    totalValue: 0,
    totalByDay: false,
  },
  //{ id:7, extraName: "outOfHoursReturn", max: 1, min:0, value:30,quantity:0,maxValue:10000,totalValue:0,totalByDay:false},
  //{ id:8, extraName: "Out of hours deliver after 23h00", max: 1, min:0, value:60,quantity:0,maxValue:10000,totalValue:0,totalByDay:false},
];

export function handleQuantityChange(extraIndex, quantity) {
  const extra = EXTRAS[extraIndex];

  if (quantity < extra.min) {
    quantity = extra.min;
  } else if (extra.max && quantity > extra.max) {
    quantity = extra.max;
  }

  EXTRAS[extraIndex].quantity = quantity;
  EXTRAS[extraIndex].totalValue = quantity * extra.value;
}

export const DESKS = [
  { code: 41, name: "Funchal- Rua Ivens Nº12" },
  { code: 42, name: "Aeroporto da Madeira" },
  { code: 47, name: "Calheta" },
  { code: 46, name: "Norte" },
];
export const SCOOTERS = ["SCOOTER"];
export const SUVS = ["CFMV", "CGMD", "CGAV", "CGAD", "RFMD"];
export const SMALL = ["MDMV", "NBMV", "NCMV", "MDAV"];
export const MEDIUM = [
  "EDMV",
  "EDMD",
  "HXMD",
  "EDAV",
  "CDMV",
  "CDMD",
  "HDMD",
  "CWMD",
  "CDAD",
  "CDAV",
  "SDMD",
];
export const BIG = ["CWMD", "SDMD"];
export const CABRIO = ["NTMV", "CTMV", "CTAV"];
export const ELETRIC = ["HDAE"];
export const PREMIUM = ["DDAD", "PDAD"];
export const MINIVAN = ["SVMD", "SFAD", "JVMR", "CMMD"];
export const VAN = ["LVMD", "RVAD"];
export const AUTOMATIC = [
  "HDAE",
  "RVAD",
  "CTAV",
  "MDAV",
  "EDAV",
  "CDAV",
  "CDAD",
  "CGAV",
  "CGAD",
  "DDAD",
  "PDAD",
  "SFAD",
];
export const MANUAL = [
  "MDMV",
  "NBMV",
  "NCMV",
  "EDMV",
  "CFMV",
  "CDMV",
  "EDMD",
  "HXMD",
  "CGMD",
  "CDMD",
  "HDMD",
  "CMMR",
  "LVMD",
  "CWMD",
  "NTMV",
  "CTMV",
  "SDMD",
  "RFMD",
  "SVMD",
  "JVMR",
];
export const DIESEL = [
  "EDMD",
  "HXMD",
  "CGMD",
  "CDMD",
  "HDMD",
  "CMMR",
  "LVMD",
  "RVAD",
  "CWMD",
  "CDAD",
  "SDMD",
  "RFMD",
  "CGAD",
  "DDAD",
  "PDAD",
  "SVMD",
  "SFAD",
];
export const PETROL = [
  "MDMV",
  "NBMV",
  "NCMV",
  "EDMV",
  "CFMV",
  "CDMV",
  "NTMV",
  "CTMV",
  "CTAV",
  "MDAV",
  "EDAV",
  "CDAV",
  "CGAV",
  "CMMR",
];

export const VEHICLES = [
  {
    id: 0,
    brand: "Honda PCX",
    fuelType: "Petrol",
    transmission: "Automatic",
    seats: 2,
    doors: 0,
    picture: "1.HONDA_PCX_125.png",
    code: "SCOOTER",
  },
  {
    id: 1,
    brand: "FIAT PANDA",
    fuelType: "Petrol",
    transmission: "Manual",
    seats: 4,
    doors: 5,
    picture: "2.fiat_panda.png",
    code: "MDMV",
  },
  {
    id: 2,
    brand: "FIAT 500",
    fuelType: "Petrol",
    transmission: "Manual",
    seats: 4,
    doors: 3,
    picture: "4.fiat_500c.png",
    code: "NBMV",
  },
  {
    id: 3,
    brand: "Peugeot 108 Top",
    fuelType: "Petrol",
    transmission: "Manual",
    seats: 4,
    doors: 5,
    picture: "3.Peugeot_108.png",
    code: "NCMV",
  },
  {
    id: 4,
    brand: "VW POLO",
    fuelType: "Petrol",
    transmission: "Manual",
    seats: 5,
    doors: 5,
    picture: "27.VW_POLO.png",
    code: "EDMV",
  },
  {
    id: 5,
    brand: "Peugeot 2008",
    fuelType: "Petrol",
    transmission: "Manual",
    seats: 5,
    doors: 5,
    picture: "9.Peugeot_2008.png",
    code: "CFMV",
  },
  {
    id: 6,
    brand: "Opel Astra",
    fuelType: "Petrol",
    transmission: "Manual",
    seats: 5,
    doors: 5,
    picture: "18.opel_astra.png",
    code: "CDMV",
  },
  {
    id: 7,
    brand: "Opel Corsa",
    fuelType: "Diesel",
    transmission: "Manual",
    seats: 5,
    doors: 5,
    picture: "21.opel_corsa_e.png",
    code: "EDMD",
  },
  {
    id: 7,
    brand: "Peugeot 208 GPS",
    fuelType: "Diesel",
    transmission: "Manual",
    seats: 5,
    doors: 5,
    picture: "peugeot208.png",
    code: "HXMD",
  },
  {
    id: 8,
    brand: "Dacia Duster",
    fuelType: "Diesel",
    transmission: "Manual",
    seats: 5,
    doors: 5,
    picture: "16.dacia_duster.png",
    code: "CGMD",
  },
  {
    id: 9,
    brand: "Opel Astra",
    fuelType: "Diesel",
    transmission: "Manual",
    seats: 5,
    doors: 5,
    picture: "18.opel_astra.png",
    code: "CDMD",
  },
  {
    id: 10,
    brand: "BMW 116D",
    fuelType: "Diesel",
    transmission: "Manual",
    seats: 5,
    doors: 5,
    picture: "19.bmw_116_d.png",
    code: "HDMD",
  },
  {
    id: 11,
    brand: "Renault Kangoo",
    fuelType: "Diesel",
    transmission: "Manual",
    seats: 5,
    doors: 5,
    picture: "20.renault_kango.png",
    code: "CMMD",
  },
  {
    id: 12,
    brand: "Peugeot Traveller",
    fuelType: "Diesel",
    transmission: "Manual",
    seats: 9,
    doors: 4,
    picture: "23.PEUGEOT_TRAVELLER.png",
    code: "LVMD",
  },
  {
    id: 15,
    brand: "Opel Insignia Station",
    fuelType: "Diesel",
    transmission: "Manual",
    seats: 5,
    doors: 5,
    picture: null,
    code: "CWMD",
  },
  {
    id: 16,
    brand: "Fiat 500 Cabrio",
    fuelType: "Petrol",
    transmission: "Manual",
    seats: 4,
    doors: 3,
    picture: "4.fiat_500c.png",
    code: "NTMV",
  },
  {
    id: 17,
    brand: "Mini One Cabrio",
    fuelType: "Petrol",
    transmission: "Manual",
    seats: 4,
    doors: 3,
    picture: "25.mini_one_convertible.png",
    code: "CTMV",
  },
  {
    id: 17,
    brand: "Mini One Cabrio",
    fuelType: "Petrol",
    transmission: "Automatic",
    seats: 4,
    doors: 3,
    picture: null,
    code: "CTAV",
  },
  {
    id: 18,
    brand: "Mitsubishi Space Star",
    fuelType: "Petrol",
    transmission: "Automatic",
    seats: 4,
    doors: 5,
    picture: "26.mitsubishi_space_star.png",
    code: "MDAV",
  },
  {
    id: 19,
    brand: "VW POLO",
    fuelType: "Petrol",
    transmission: "Automatic",
    seats: 5,
    doors: 5,
    picture: "27.VW_POLO.png",
    code: "EDAV",
  },
  {
    id: 20,
    brand: "Opel Astra",
    fuelType: "Petrol",
    transmission: "Automatic",
    seats: 5,
    doors: 5,
    picture: "18.opel_astra.png",
    code: "CDAV",
  },
  {
    id: 21,
    brand: "Peugeot 308",
    fuelType: "Diesel",
    transmission: "Automatic",
    seats: 5,
    doors: 5,
    picture: "peugeot308.png",
    code: "CDAD",
  },
  {
    id: 22,
    brand: "Opel Insignia",
    fuelType: "Diesel",
    transmission: "Manual",
    seats: 5,
    doors: 5,
    picture: "28.opel_insignia.png",
    code: "SDMD",
  },
  {
    id: 23,
    brand: "Volkswagen Taigo",
    fuelType: "Petrol",
    transmission: "Automatic",
    seats: 5,
    doors: 5,
    picture: "31.vw_taigo.png",
    code: "CGAV",
  },
  {
    id: 24,
    brand: "Peugeot 2008",
    fuelType: "Diesel",
    transmission: "Automatic",
    seats: 5,
    doors: 5,
    picture: "37.Peugeot_2008_diesel.png",
    code: "CGAD",
  },
  {
    id: 25,
    brand: "Mercedes-Benz A180D",
    fuelType: "Diesel",
    transmission: "Automatic",
    seats: 5,
    doors: 5,
    picture: "32.mercedes180d.png",
    code: "DDAD",
  },
  {
    id: 26,
    brand: "Mercedes-Benz C220D",
    fuelType: "Diesel",
    transmission: "Automatic",
    seats: 5,
    doors: 5,
    picture: "33.mercedes_c220_d.png",
    code: "PDAD",
  },
  {
    id: 27,
    brand: "VW Touran",
    fuelType: "Diesel",
    transmission: "Manual",
    seats: 7,
    doors: 5,
    picture: "34.VOLKSWAGEN_TOURAN.png",
    code: "SVMD",
  },
  {
    id: 28,
    brand: "Skoda Kodiaq",
    fuelType: "Diesel",
    transmission: "Automatic",
    seats: 7,
    doors: 5,
    picture: "35.skoda_kodiaq.png",
    code: "SFAD",
  },
  {
    id: 29,
    brand: "Dacia Jogger",
    fuelType: "Petrol",
    transmission: "Manual",
    seats: 7,
    doors: 5,
    picture: "36.Dacia_Jogger.png",
    code: "JVMR",
  },
];
