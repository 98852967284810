import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import useWindowSize from "./useWindowSize";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#38AECC",
    },
    terceary: {
      main: "#F4F4F4",
    },
  },
});

const responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 1 },
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 }, // Display 4 cars
  tablet: { breakpoint: { max: 1024, min: 768 }, items: 1 },
  mobile: { breakpoint: { max: 768, min: 0 }, items: 1 },
};

const About = () => {
  const { t } = useTranslation();
  const size = useWindowSize();
  return (
    <ThemeProvider theme={theme}>
      <Typography
        variant="h4"
        component="div"
        sx={{ color: "primary.main", textAlign: "center", mt: "1rem" }}
      >
        {t("about")}
      </Typography>
      {/* <Typography
        align="center"
        sx={{ marginLeft: 10, marginRight: 10, mt: 2 }}
      >
        {t("aboutUsText")}
      </Typography> */}
      {size.width > 650 ? (
        <div className="h-20 ml-20 mr-20 sm:h-40 xl:h-40 2xl:h-40">
          <Carousel
            swipeable
            draggable
            infinite
            autoPlay={true}
            autoPlaySpeed={3000}
            arrows={false}
            responsive={responsive}
          >
            <Typography
              variant="h5"
              sx={{
                color: "secondary.main",
                textAlign: "center",
                ml: "1rem",
                mt: "1rem",
              }}
            >
              {t("ourHistory")}
            </Typography>
            <Typography className="ml-10 mr-10 p-4 dark:border-gray-500 dark:bg-gray-800">
              {t("about1")}
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{
                color: "secondary.main",
                textAlign: "center",
                ml: "1rem",
                mt: "1rem",
              }}
            >
              {t("promiseFulfilled")}
            </Typography>
            <Typography className="ml-10 mr-10 p-4 dark:border-gray-500 dark:bg-gray-800">
              {t("about2")}
            </Typography>
          </Carousel>
        </div>
      ) : (
        <Container>
          {" "}
          <Typography
            variant="h5"
            sx={{
              color: "secondary.main",
              textAlign: "center",
              ml: "1rem",
              mt: "1rem",
            }}
          >
            {t("ourHistory")}
          </Typography>
          <Typography className="ml-10 mr-10 p-4 dark:border-gray-500 dark:bg-gray-800">
            {t("about1")}
          </Typography>
        </Container>
      )}
    </ThemeProvider>
  );
};

export default About;
