import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CarouselComponent from "./Components/Carousel/Carousel";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import FleetDisplay from "./helpers/FleetDisplay";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#202A67",
    },
    terceary: {
      main: "#F4F4F4",
    },
  },
});

const Fleet = ({ setQuoteMessage, setChoosedCar }) => {
  const { t } = useTranslation();
  const [totalFleet, setTotalFleet] = useState(false);

  const handleTotalFLeet = () => {
    setTotalFleet(!totalFleet);
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <div>
          <Typography
            variant="h4"
            component="div"
            sx={{
              flexGrow: 1,
              color: "primary.main",
              textAlign: "center",
              mt: "5rem",
            }}
          >
            {t("fleet")}
          </Typography>
          <Box
            aria-hidden="false"
            sx={{
              marginRight: { xs: 0, sm: 5 },
              marginTop: 2,
              display: "flex", // Flexbox layout
              justifyContent: { xs: "center", sm: "flex-end" }, // Center on small screens, right on larger screens
            }}
          >
            <Button
              aria-hidden="false"
              sx={{ bottom: "10px" }}
              variant="contained"
              size="large"
              onClick={() => handleTotalFLeet()}
            >
              {t("seeAll")}
            </Button>
          </Box>
          {totalFleet ? (
            <FleetDisplay
              setQuoteMessage={setQuoteMessage}
              setChoosedCar={setChoosedCar}
            />
          ) : (
            <CarouselComponent
              setQuoteMessage={setQuoteMessage}
              setChoosedCar={setChoosedCar}
            />
          )}
        </div>
      </ThemeProvider>
    </>
  );
};

export default Fleet;
